import Check from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import { styled as styling } from "@mui/material/styles";
import Lottie from "react-lottie";
import Color from "../../assets/colors";

export const P = styling("p")({
  margin: "0",
});

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const CheckboxContainer = styling("div")({
  position: "relative",
});

export const CheckboxHover = styling("div")({
  position: "absolute",
  top: "0",
  left: "0",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  width: "100%",
  height: "100%",
});

export const CheckedBox = styling("div")((props) => ({
  color: Color.white,
  backgroundColor: Color.black,
  border: "1px solid" + Color.black,
  borderRadius: "0.5rem",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "&:hover": {
    backgroundColor: Color.white,
  },

  height: "32px",
  width: "32px",

  ...(props.size === "small" && {
    borderRadius: "0.25rem",

    height: "18px",
    width: "18px",
  }),
}));

export const UncheckedBox = styling("div")((props) => ({
  color: Color.black,
  backgroundColor: Color.white,
  border: "1px solid" + Color.black,
  borderRadius: "0.5rem",

  height: "32px",
  width: "32px",

  ...(props.size === "small" && {
    borderRadius: "0.25rem",
    height: "18px",
    width: "18px",
  }),
}));

export const GreyText = styling("span")({
  color: "rgba(0, 0, 0, 0.38)",
});

export const RoundedButton = styling(ButtonBase)((props) => ({
  border: "1px solid transparent",
  borderRadius: "6.25rem",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: 500,
  letterSpacing: "0.074px",
  whiteSpace: "nowrap",

  height: "42px",
  width: "200px",
  padding: "0 1rem",

  transition: "all 0.1s ease-in-out",

  ...(props.variant === "outlined" && {
    color: Color.black,
    backgroundColor: Color.white,
    border: "1px solid" + Color.black,

    "&:hover": {
      backgroundColor: Color.lightGrey,
      fontWeight: 700,
    },

    ...(props.color === "secondary" && {
      color: Color.hiredlyPurple,
      border: "1px solid" + Color.hiredlyPurple,

      "&:hover": {
        backgroundColor: Color.lightHiredlyPurple,
      },
    }),
  }),

  ...(props.variant === "filled" && {
    color: Color.white,
    backgroundColor: Color.hiredlyPurple,
    border: "1px solid" + Color.hiredlyPurple,

    fontWeight: 700,

    "&:hover": {
      backgroundColor: Color.darkPurple,
      border: "1px solid" + Color.darkPurple,
    },

    "&:disabled": {
      backgroundColor: Color.grey,
      border: "1px solid" + Color.grey,
      pointerEvents: "none",
    },

    ...(props.color === "primary" && {
      backgroundColor: Color.buttonGreen,
      border: "1px solid" + Color.black,

      "&:hover": {
        backgroundColor: "rgba(55, 176, 116, 1)",
      },
    }),

    ...(props.color === "secondary" && {
      backgroundColor: "rgba(53, 53, 53, 1)",
      border: "1px solid" + Color.black,

      "&:hover": {
        backgroundColor: Color.black,
      },
    }),
  }),

  ...(props.size === "medium" && {
    fontSize: "0.875rem",

    height: "36px",
    width: "160px",
  }),

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",

    minWidth: "144px",
    width: "fit-content",
    height: "36px",

    ...(props.size === "small" && {
      fontSize: "0.75rem",
      height: "30px",
    }),
  },
}));

export const ButtonText = styling(ButtonBase)({
  color: "rgba(54, 118, 35, 1)",

  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "700",
  lineHeight: "20px",
  letterSpacing: "0.112px",
});

export const CheckboxButton = styling(Checkbox)({
  padding: "0",

  "& .Mui-checked": {
    color: Color.black,
  },

  "&:hover": {
    background: "transparent",
  },
});

export const CloseButton = styling(ButtonBase)({
  borderRadius: "0.5rem",
});

export const LottieStyled = styling(Lottie)({});

export const DividerStyled = styling(Divider)({
  width: "100%",
});

export const CheckboxIcon = styling(Check)((props) => ({
  width: "18px",
  height: "18px",

  ...(props.size === "small" && {
    height: "12px",
    width: "12px",
  }),
}));
