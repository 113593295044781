import { useDispatch } from "react-redux";
import {
  updateShowSignInModalStatus,
  updateSignInModalSignUpStatus,
  updateSignUpOrigin,
} from "../../../redux/actions/navbar_action";
import ForYouJobList from "../ForYouJobList/ForYouJobList";
import { BoxContainer } from "../styles";
import {
  ButtonStyled,
  LockIcon,
  VisitorCard,
  VisitorCardContainer,
  VisitorJobListContainer,
  VisitorSubtitle,
  VisitorTitle,
  VisitorWrapper,
} from "./styles";

export default function VisitorPlaceholder() {
  const dispatch = useDispatch();

  function handleTriggerLogin() {
    dispatch(updateSignUpOrigin("job_recommendation")).then(() => {
      dispatch(updateShowSignInModalStatus(true));
    });
  }

  function handleTriggerSignup() {
    dispatch(updateSignUpOrigin("job_recommendation")).then(() => {
      dispatch(updateShowSignInModalStatus(true)).then(() => {
        dispatch(updateSignInModalSignUpStatus(true));
      });
    });
  }

  return (
    <VisitorWrapper>
      <VisitorCardContainer>
        <VisitorCard>
          <LockIcon />
          <BoxContainer
            flexDirection={"column"}
            alignItems={"center"}
            margin={"2rem 0 0"}
          >
            <VisitorTitle>
              Don't just search for jobs, get matched!
            </VisitorTitle>
            <VisitorSubtitle>
              Sign up now for personalised recommendations
            </VisitorSubtitle>
          </BoxContainer>
          <BoxContainer gap={"1rem"} margin={"2.5rem 0 0"}>
            <ButtonStyled onClick={handleTriggerLogin}>Log In</ButtonStyled>
            <ButtonStyled variant={"filled"} onClick={handleTriggerSignup}>
              Sign Up
            </ButtonStyled>
          </BoxContainer>
        </VisitorCard>
      </VisitorCardContainer>
      <VisitorJobListContainer>
        <ForYouJobList isVisitor={true} />
      </VisitorJobListContainer>
    </VisitorWrapper>
  );
}
