import { Toolbar } from "@mui/material";
import Head from "next/head";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import ToResolveClassNameError from "../../components/hoc/ToResolveClassNameError";
import WithResume from "../../components/hoc/WithResume";
import BacklinkComponent from "../../components/jobs/FooterWriteup/FooterLinks";
import HiredlyWriteup from "../../components/jobs/FooterWriteup/HiredlyWriteup";
import JobListSkeleton from "../../components/jobs/JobListSkeleton/JobListSkeleton";
import JobListSubheader from "../../components/jobs/JobListSubheader/JobListSubheader";
import NewJobListWrapper from "../../components/jobs/JobListWrapper/NewJobListWrapper";
import SpecialisationWriteup from "../../components/jobs/SpecialisationWriteup/SpecialisationWriteup";
import SharedChat from "../../components/shared/SharedChat/SharedChat";
import SharedCollapsibleFooter from "../../components/shared/SharedCollapsibleFooter/SharedCollapsibleFooter";
import * as config from "../../config/config";
import { formJobQueryParam } from "../../functions/job";
import { jobTypes } from "../../helpers/constant";
import {
  check404,
  checkNoIndex,
  convertPageToString,
  convertUrlToIds,
  getTitle,
} from "../../helpers/filter_management";
import {
  getCmsLocations,
  getCmsSpecialisations,
  getCmsSubSpecialisation,
  getFooterCompanyWriteup,
  getJobsWithoutRedux,
  getSpecialisationWithoutRedux,
  getStateRegionWithoutRedux,
} from "../../redux/actions/job_action";

function JobFiltersPage(props) {
  const { specialisationList, stateRegions } = props;

  let metaTitle =
    "New Job Vacancies in Malaysia - Search By Location, Specialisation & Job Type | Hiredly";
  let metaDescription =
    "Easy and fast job search on one of Malaysia's leading job portal. Find remote and work-from-home job vacancies for full-time and internship career opportunities in Malaysia.";
  let metaIndex;

  let filterParams;
  let pageParams;
  let subheaderTitle;

  const router = useRouter();

  // const jobResult = useSelector((state) => state?.jobs?.JobNoResult);

  // only run when router is ready to prevent undefined error
  if (router.isReady) {
    const query = router.query;
    filterParams = convertUrlToIds(query, specialisationList, stateRegions);
    pageParams = convertPageToString(query);
    metaIndex = checkNoIndex(query, filterParams);
    subheaderTitle = getTitle(filterParams, specialisationList, stateRegions);
  }

  const jobListTab =
    typeof window === "undefined"
      ? 1
      : useSelector((state) => state.jobs.jobListTab);

  return (
    <Fragment>
      <Head>
        {/* <!-- Primary Meta Tags --> */}
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        {metaIndex && <meta name="robots" content="noindex, nofollow" />}

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" key={"ogType"} />
        <meta
          property="og:url"
          content="https://my.hiredly.com/jobs"
          key="ogUrl"
        />
        <meta property="og:title" content={metaTitle} key="ogTitle" />
        <meta
          property="og:description"
          content={metaDescription}
          key="ogDescription"
        />
        <meta
          property="og:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="ogImage"
        />

        {/* <!-- Twitter --> */}
        <meta
          property="twitter:card"
          content="summary_large_image"
          key="twitterCard"
        />
        <meta
          property="twitter:url"
          content="https://my.hiredly.com/jobs"
          key="twitterUrl"
        />
        <meta property="twitter:title" content={metaTitle} key="twitterTitle" />
        <meta
          property="twitter:description"
          content={metaDescription}
          key="twitterDescription"
        />
        <meta
          property="twitter:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="twitterImage"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="canonical" href={props.pageUrl} />
      </Head>
      <ToResolveClassNameError>
        <Toolbar
          sx={{
            "@media(min-width: 1280px)": {
              minHeight: "64px",
            },
            "@media(max-width: 1279px)": {
              minHeight: "52px",
            },
            "@media(max-width: 639px)": {
              minHeight: "60px",
            },
          }}
        />
      </ToResolveClassNameError>
      <JobListSubheader titleInfo={subheaderTitle ?? null} />
      <NewJobListWrapper
        jobs={props.jobs}
        isLoggedIn={props.isLoggedIn}
        filterParams={filterParams}
        pageParams={pageParams}
      />

      {/* SSR component START */}

      <JobListSkeleton filterParams={filterParams} pageParams={pageParams} />
      {jobListTab === 1 && (
        <>
          <BacklinkComponent
            cmsSpecialisations={props.cmsSpecialisations}
            cmsLocations={props.cmsLocations}
          />
          <SpecialisationWriteup specialisation={props.specialisation} />
          <HiredlyWriteup companyWriteup={props.companyWriteup} />
        </>
      )}
      <SharedCollapsibleFooter />
      {/* SSR component END */}

      <ToResolveClassNameError>
        <SharedChat />
      </ToResolveClassNameError>
    </Fragment>
  );
}

export async function getStaticPaths() {
  const cmsSpecialisations = await getCmsSpecialisations();
  const cmsLocations = await getCmsLocations();

  let paths = Array.isArray(cmsSpecialisations)
    ? cmsSpecialisations.map((specialisation) => {
        return {
          params: {
            filter: [`jobs-in-${specialisation?.attributes?.slug}`],
          },
        };
      })
    : [];

  Array.isArray(cmsLocations)
    ? paths.concat(
        cmsLocations.map((location) => {
          paths.push({
            params: {
              filter: [`jobs-in-${location?.attributes?.slug}`],
            },
          });
        })
      )
    : paths;

  return {
    paths: paths,
    fallback: "blocking",
  };
}

export async function getStaticProps({ params }) {
  const pageExist = check404(params.filter, jobTypes);

  if (pageExist) {
    return {
      notFound: true,
      revalidate: 60 * 5,
    };
  }

  let pageUrl = "https://my.hiredly.com/";
  if (Array.isArray(params.filter)) {
    pageUrl = `https://my.hiredly.com/${params.filter.join("/")}`;
  }

  let matchSpec = {};

  // only call the api if its not a 404
  const companyWriteup = await getFooterCompanyWriteup();
  const specialisationList = await getSpecialisationWithoutRedux();
  const stateRegions = await getStateRegionWithoutRedux();
  const cmsSpecialisations = await getCmsSpecialisations();
  const cmsLocations = await getCmsLocations();

  // if filter array 2 or more, get writeup of sub specialisation
  if (Array.isArray(params.filter) && params.filter.length > 1) {
    const subSpec = params.filter[1] ?? null;

    matchSpec = (await getCmsSubSpecialisation({ keyword: subSpec })) ?? {};
  } else {
    // THIS FOLLOWING CODE WILL NOT WORK IF THERE IS MORE THAN 1 TYPE OF FILTER
    const filterStr = Array.isArray(params.filter)
      ? params.filter.join("/")
      : "";

    const retrieve = ([slug]) => slug;

    // this function will not work if there is more than 1 type of filter
    const cleanSlug = retrieve(filterStr.split("jobs-in-").slice(-1));

    matchSpec = Array.isArray(cmsSpecialisations)
      ? cmsSpecialisations.find(
          (spec) => spec.attributes?.slug === cleanSlug
        ) ?? {}
      : {};
  }

  const filterParams = convertUrlToIds(
    { filter: params.filter },
    specialisationList,
    stateRegions
  );

  const jobParams = formJobQueryParam(filterParams, false);

  const pagination = {
    first: 30,
    last: null,
    startCursor: null,
    endCursor: null,
  };

  const jobs = await getJobsWithoutRedux(jobParams, pagination);

  return {
    props: {
      jobs: jobs?.nodes,
      jobParams: jobParams,
      pagination: pagination,
      path: params.filter,
      pageUrl: pageUrl,
      companyWriteup: companyWriteup ?? null,
      specialisationList: specialisationList ?? null,
      cmsSpecialisations: cmsSpecialisations ?? null,
      stateRegions: stateRegions ?? null,
      cmsLocations: cmsLocations ?? null,
      specialisation: matchSpec ?? null,
    },
    revalidate: 60 * 5,
  };
}

export default WithResume(JobFiltersPage);
