import { useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import resumeUpload from "../../../assets/lotties/job-list/resume-uploading.json";
import { convertBase64, setItem } from "../../../helpers/data_management";
import { updateForYouResumeDialogStatus } from "../../../redux/actions/dialog_action";
import {
  newResumeUpload,
  updateResumeBuildingStatus,
  updateResumeDoneStatus,
  updateResumeProgressPopupStatus,
  updateResumeUploadingStatus,
  updateUser,
} from "../../../redux/actions/user_action";
import { InputStyled } from "../ForYouResume/styles";
import {
  BoxContainer,
  CloseButton,
  DividerStyled,
  GreyText,
  LottieStyled,
  RoundedButton,
} from "../styles";
import {
  CloseIcon,
  DialogContainer,
  DialogContent,
  DialogHeader,
  DropResumeText,
  DropzoneContainer,
  FileIcon,
  HeaderText,
  LinearProgressStyled,
  OrText,
  ResumeDetailContainer,
  ResumeFormatText,
  ResumeNameText,
  ResumeStatusChip,
  SubheadingText,
  UploadingText,
} from "./styles";

export default function ForYouResumeDialog() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 640px)");

  const user = useSelector((state) => state.user?.user);
  const dialogOpen = useSelector(
    (state) => state.dialog?.showForYouResumeDialog
  );

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [triggerLoading, setTriggerLoading] = useState(false);

  const inputRef = useRef(null);

  const resumeName = getResumeName(user?.resume);
  const resumeStatus = user?.state;

  const resumeStatusText = useMemo(() => {
    return resumeStatus === "approved"
      ? "Approved"
      : resumeStatus === "rejected"
      ? "Rejected"
      : "In Review";
  }, [resumeStatus]);

  function getResumeName(resume) {
    if (resume) {
      const splitted = resume.split("/");
      const length = splitted.length;

      if (length > 1) {
        return splitted[length - 1];
      }
    }

    return "-";
  }

  function handleCloseDialog() {
    dispatch(updateForYouResumeDialogStatus(false));
  }

  function handleBrowseFiles() {
    toast.dismiss();
    inputRef.current.click();
  }

  function handleFileChange(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleCvFileChange(e.target.files[0]);
      e.target.value = null;
    }
  }

  async function handleCvFileChange(file, autoGenerated = false) {
    if (!file) return;

    if ((file.size / 1024 / 1024).toFixed(4) > 2) {
      setValidResume(false);
      setInvalidSize(true);
      return;
    }

    if (
      !(
        file.name?.includes(".docx") ||
        file.name?.includes(".doc") ||
        file.name?.includes(".pdf")
      )
    ) {
      setValidResume(false);
      setInvalidSize(false);
      return;
    }

    // Converting PDF file to base64
    convertBase64(file).then((cvFile) => {
      // Trigger graphQL to upload cvFile
      let params = {
        resume: cvFile,
        canCancel: autoGenerated,
        origin: "profile",
        user_resume: user.resume,
        autoGenerated: autoGenerated,
        state: autoGenerated ? "approved" : "review",
      };

      setProgress(0);
      setUploading(true);
      setTriggerLoading(true);

      // Show progress popup
      dispatch(updateResumeProgressPopupStatus(true));

      // Hide building
      dispatch(updateResumeBuildingStatus(false));

      // Show uploading
      dispatch(updateResumeUploadingStatus(true));

      dispatch(newResumeUpload(params)).then((response) => {
        // Hide uploading
        dispatch(updateResumeUploadingStatus(false));

        if (response.type === "UPDATE_USER_CV_SUCCEED") {
          // Show done then hide done after 2 seconds
          dispatch(updateResumeDoneStatus(true));
          setTimeout(() => {
            dispatch(updateResumeProgressPopupStatus(false));
            dispatch(updateResumeDoneStatus(false));
          }, 2000);

          const message = (
            <>
              <span>Resume updated. </span>
              <GreyText>Reviewing...</GreyText>
            </>
          );

          toast.success(message, {
            timer: 3000,
          });

          // Update redux user information
          dispatch(updateUser(response.user));

          // Update local information
          setItem("USER_PROFILE", JSON.stringify(response.user));
          setUploading(false);
          setProgress(100);
          dispatch(updateForYouResumeDialogStatus(false));
        } else {
        }
      });
    });
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (triggerLoading) {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }
    }, 75);

    return () => {
      clearInterval(timer);
    };
  }, [triggerLoading]);

  return (
    <DialogContainer open={dialogOpen} onClose={handleCloseDialog}>
      <DialogHeader>
        <BoxContainer flexDirection="column">
          <HeaderText>Your Resume</HeaderText>
          <SubheadingText>
            Update your latest resume for more accurate job recommendations!
          </SubheadingText>
        </BoxContainer>
        <CloseButton onClick={handleCloseDialog}>
          <CloseIcon />
        </CloseButton>
      </DialogHeader>
      <DialogContent>
        <ResumeDetailContainer>
          <ResumeNameText>{resumeName}</ResumeNameText>
          <ResumeStatusChip status={resumeStatus}>
            {resumeStatusText}
          </ResumeStatusChip>
        </ResumeDetailContainer>
        <DropzoneContainer>
          <LottieStyled
            options={{
              loop: true,
              autoplay: true,
              animationData: resumeUpload,
            }}
            width={"104px"}
            height={"80px"}
          />
          {!uploading ? (
            <>
              <BoxContainer
                flexDirection="column"
                alignItems="center"
                gap="0.625rem"
              >
                {!isMobile && (
                  <>
                    <DropResumeText>Drop your resume here</DropResumeText>
                    <DividerStyled>
                      <OrText>or</OrText>
                    </DividerStyled>
                  </>
                )}
                <RoundedButton
                  variant="filled"
                  size="medium"
                  onClick={handleBrowseFiles}
                >
                  Browse Files
                </RoundedButton>
              </BoxContainer>
              <ResumeFormatText>
                Support file type: .pdf, doc, .docx (2MB max)
              </ResumeFormatText>
            </>
          ) : (
            <BoxContainer
              gap={"0.5rem"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <FileIcon />
              <BoxContainer flexDirection={"column"} gap={"0.375rem"}>
                <BoxContainer
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <UploadingText>{resumeName}</UploadingText>
                  <UploadingText>{`${parseInt(progress)}%`}</UploadingText>
                </BoxContainer>
                <LinearProgressStyled variant="determinate" value={progress} />
              </BoxContainer>
            </BoxContainer>
          )}
        </DropzoneContainer>
      </DialogContent>
      <InputStyled
        ref={inputRef}
        accept=".pdf,.docx,.doc"
        type="file"
        onChange={handleFileChange}
      />
    </DialogContainer>
  );
}
