import LockClosedIcon from "@heroicons/react/24/solid/LockClosedIcon";
import ButtonBase from "@mui/material/ButtonBase";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "../../../assets/colors";

export const VisitorWrapper = styling("div")({
  position: "relative",

  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  height: "80svh",
  width: "100%",
  maxWidth: "1440px",
  padding: "1.5rem 0",
  margin: "0 auto",

  "@media (max-width: 1280px)": {
    maxWidth: "972px",
  },

  "@media (max-width: 640px)": {
    width: "auto",
    padding: "0",
  },
});

export const VisitorJobListContainer = styling("div")({
  pointerEvents: "none",
  userSelect: "none",
});

export const VisitorCardContainer = styling("div")({
  position: "absolute",
  zIndex: 3,

  background:
    "linear-gradient(180deg, rgba(245, 245, 245, 0.20) 0%, #F5F5F5 78.93%)",
  backdropFilter: "blur(4px)",

  width: "100%",
  height: "100%",

  "@media (max-width: 640px)": {
    height: "calc(100svh - 100px)",
  },
});

export const VisitorCard = styling("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  height: "70%",
  width: "100%",
  padding: "2rem auto 2.5rem",

  "@media (max-width: 1280px)": {
    width: "100%",
    margin: "1rem auto 0",
    padding: "0 1rem",
  },

  "@media (max-width: 640px)": {
    height: "80%",
  },
}));

export const ButtonStyled = styling(ButtonBase)((props) => ({
  color: props.variant === "filled" ? Color.white : Color.black,
  backgroundColor:
    props.variant === "filled" ? Color.hiredlyPurple : Color.white,
  border:
    props.variant === "filled"
      ? `1px solid ${Color.hiredlyPurple}`
      : `1px solid ${Color.black}`,
  borderRadius: "6.25rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: props.variant === "filled" ? "700" : "500",
  letterSpacing: "0.074px",
  whiteSpace: "nowrap",

  height: "2.625rem",
  width: "12.5rem",

  transition: "all 0.125s ease-in-out",

  "@media (min-width: 1280px)": {
    "&:hover": {
      color: props.variant === "filled" ? Color.white : Color.black,
      backgroundColor:
        props.variant === "filled" ? Color.darkPurple : Color.lightGrey,
      scale: "1.025",
    },
  },

  "@media (max-width: 640px)": {
    height: "2.25rem",
    width: "9.5rem",
  },
}));

export const VisitorTitle = styling(Typography)({
  fontSize: "1.5625rem",
  fontWeight: 500,
  lineHeight: "32px",
  textAlign: "center",
  whiteSpace: "nowrap",

  "@media (max-width: 640px)": {
    fontSize: "1.0625rem",
    lineHeight: "24px",
  },
});

export const VisitorSubtitle = styling(Typography)({
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "center",
  whiteSpace: "wrap",

  "@media (max-width: 640px)": {},
});

export const LockIcon = styling(LockClosedIcon)({
  height: "120px",
  width: "120px",

  "@media (max-width: 640px)": {
    height: "60px",
    width: "60px",
  },
});
