import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import DocumentIcon from "@heroicons/react/24/outline/DocumentIcon";
import Dialog from "@mui/material/Dialog";
import LinearProgress from "@mui/material/LinearProgress";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import { P } from "../styles";

export const DialogContainer = styling(Dialog)({
  "& .MuiDialog-container .MuiPaper-root": {
    border: "1.5px solid " + Color.black,
    borderRadius: "1.5rem",
    overflow: "clip",

    maxWidth: "720px",
    width: "100%",

    transition: "all 0.3s ease-in-out",

    "@media (max-width: 640px)": {
      maxWidth: "328px",
    },
  },
});

export const DialogHeader = styling("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",

  borderBottom: "1px solid" + Color.grey,

  padding: "1rem 1.5rem",

  "@media (max-width: 640px)": {
    padding: "0.875rem",
  },
});

export const DialogContent = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",

  padding: "1rem 1.5rem 1.5rem",

  "@media (max-width: 640px)": {
    padding: "0.875rem",
  },
});

export const ResumeDetailContainer = styling("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  borderRadius: "0.625rem",
  border: "1px solid" + Color.grey,

  padding: "1rem 0.875rem",
});

export const DropzoneContainer = styling("div")({
  background: Color.lightGrey,
  border: "1px dashed" + Color.darkGrey,
  borderRadius: "0.625rem",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  maxHeight: "280px",
  height: "100%",
  width: "100%",
  padding: "1.5rem",

  "@media (max-width: 640px)": {
    padding: "1rem",
  },
});

export const HeaderText = styling(P)({
  color: "rgba(0, 0, 0, 0.87)",

  fontSize: "1.25rem",
  fontWeight: "700",
  lineHeight: "24px",

  "@media (max-width: 640px)": {
    fontSize: "1rem",
  },
});

export const SubheadingText = styling(P)({
  color: "rgba(0, 0, 0, 0.60)",

  fontSize: "0.875rem",
  fontStyle: "italic",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
  },
});

export const ResumeNameText = styling(P)({
  color: Color.black,

  fontSize: "0.875rem",
  lineHeight: "20px",
  letterSpacing: "0.112px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const UploadingText = styling(P)({
  fontSize: "0.875rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "165px",
});

export const DropResumeText = styling(P)({
  color: Color.hiredlyPurple,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  fontSize: "0.875rem",
  fontWeight: "700",

  height: "36px",
  padding: "0 1.25rem",
});

export const OrText = styling(P)({
  color: Color.black,

  fontSize: "0.875rem",
  lineHeight: "20px",
  letterSpacing: "0.112px",
});

export const ResumeFormatText = styling(P)({
  color: "rgba(0, 0, 0, 0.60)",

  fontSize: "0.75rem",
  lineHeight: "20px",
  textAlign: "center",

  margin: "0.75rem 0 0",

  "@media (max-width: 640px)": {
    margin: "0",
  },
});

export const ResumeStatusChip = styling("div")((props) => ({
  borderRadius: "6.25rem",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  fontSize: "0.625rem",
  fontWeight: "700",
  whiteSpace: "nowrap",

  height: "21px",
  padding: "0 0.625rem",

  ...(props.status === "rejected" && {
    color: Color.reject,
    backgroundColor: "rgba(190, 66, 66, 0.1)",
  }),

  ...(props.status === "in_review" && {
    color: "rgba(192, 132, 12, 1)",
    backgroundColor: "rgba(242, 175, 41, 0.14)",
  }),

  ...(props.status === "approved" && {
    color: "rgba(10, 132, 12, 1)",
    backgroundColor: "rgba(10, 132, 12, 0.10)",
  }),
}));

export const LinearProgressStyled = styling(LinearProgress)({
  borderRadius: "10px",
});

export const CloseIcon = styling(XMarkIcon)({
  color: Color.black,
  height: "24px",
  width: "24px",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    color: Color.darkGrey,
  },
});

export const FileIcon = styling(DocumentIcon)({
  height: "24px",
  width: "24px",
});
