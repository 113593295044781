import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import SparklesIcon from "@heroicons/react/24/outline/SparklesIcon";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import { PopupButton } from "@typeform/embed-react";
import Color from "../../../assets/colors";
import SparkleIcon from "../../../assets/svg/sparkle-icon.svg";
import { assetDomain } from "../../../config/config";
import { Link } from "@mui/material";

export const CardContainer = styling(Box)((props) => ({
  position: "relative",
  justifyContent: "space-between",
  display: props.visibility ? "flex" : "none",
  alignItems: "center",

  background: `url(${assetDomain}/images/hiredly/work-persona-background-desktop.webp)`,
  backgroundSize: "cover",
  backgroundPosition: "right",
  backgroundRepeat: "no-repeat",
  backgroundColor: "#FFE6A7",
  border: "2px solid #BF8A00",
  borderRadius: "0.625rem",

  padding: "1rem",

  "@media (min-width: 1280px)": {
    "&:hover": {
      "* > .quiz-button": {
        transition:
          "box-shadow 0.075s ease-in-out, transform 0.10s ease-in-out",
        border: `2px solid ${Color.black}`,
        boxShadow: "4px 4px 0px rgba(0, 0, 0, 1)",
        transform: "translate(-4px, -4px)",
      },
    },
  },

  "@media (max-width: 640px)": {
    flexDirection: "column",
    alignItems: "center",
    gap: "0.5rem",

    background: `url(${assetDomain}/images/hiredly/work-persona-background-mobile.webp)`,
    backgroundPosition: "center",

    padding: "0.625rem",
  },
}));

export const BoxContainer = styling(Box)({
  display: "flex",
  width: "100%",
});

export const ImageStyled = styling("img")({
  backgroundColor: Color.white,
  border: "2px solid #BF8A00",
  borderRadius: "0.625rem",
  height: "82px",
  minWidth: "82px",
  objectFit: "cover",

  "@media (max-width: 640px)": {
    borderRadius: "0.375rem",
    height: "50px",
    minWidth: "50px",
  },
});

export const TextStyled = styling(Typography)({});

export const CloseButton = styling(ButtonBase)(({buttonType}) => ({
  position: "absolute",
  top: buttonType == "survey" ? "5px" : "1rem",
  right: buttonType == "survey" ? "5px" : "1rem",

  "@media (max-width: 640px)": {
    top: buttonType == "survey" ? "1.5px" : "0.675rem",
    right: buttonType == "survey" ? "1.5px" : "0.675rem",
  },
}));

export const CloseIcon = styling(XMarkIcon)(({buttonType}) => ({
  color: buttonType == "survey" ? Color.white : Color.black,
  height: "20px",
  width: "20px",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    color: Color.darkGrey,
  },
}));

export const SparklesIconStyled = styling(SparklesIcon)({
  height: "24px",
  width: "24px",

  "@media (max-width: 640px)": {
    height: "16px",
    width: "16px",
  },
});

export const SingleSparkleIcon = styling(SparkleIcon)({
  position: "absolute",
});

export const TypeformButton = styling(PopupButton)((props) => ({
  textWrap: "nowrap",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.625rem",
  flexShrink: 0,

  color: Color.black,
  backgroundColor: Color.white,
  border: `2px solid ${Color.black}`,
  borderRadius: "0.5rem",

  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  letterSpacing: "0.112px",

  height: "2.625rem",
  padding: "0 0.75rem 0 1rem",
  margin: "1rem 0 0",

  "@media (max-width: 1280px)": {
    border: `2px solid ${Color.black}`,
    boxShadow: "4px 4px 0px rgba(0, 0, 0, 1)",
    transform: "translate(-4px, -4px)",
  },

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",

    border: `1px solid ${Color.black}`,
    borderRadius: "0.25rem",
    boxShadow: "2px 2px 0px rgba(0, 0, 0, 1)",
    transform: "translate(-4px, -4px)",

    height: "1.875rem",
    width: "45svw",
    padding: "0 0.75rem",
  },
}));

//Stlyes for Work Persona Survey Banner
export const StyledWorkPersonaContainer = styling(Link)(({show}) => ({

  display: show ? "flex" : "none",
  position: "relative",
  width: "100%",
  maxWidth: "916px",
  aspectRatio: "916/168",

  "@media (max-width: 767.9px)": {
    maxWidth: "720px",
    aspectRatio: "720/168",
  },
}));

export const StyledWorkPersonaBanner = styling(Box)({

  width: "100%",
  height: "100%",
  background: `url(${assetDomain}/images/hiredly/work-culture-survey-banner-lg.webp)`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderRadius: "0.625rem",

  "@media (max-width: 767.9px)": {
    background: `url(${assetDomain}/images/hiredly/work-culture-survey-banner-md.webp)`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
});