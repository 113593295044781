import { useDispatch, useSelector } from "react-redux";
import { updateRefreshDialogStatus } from "../../../redux/actions/dialog_action";
import {
  ButtonStyled,
  CloseButton,
  CloseIcon,
  DialogContainer,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "./styles";

export default function RefreshDialog(props) {
  const { handleRefreshJobs, jobSelectedState } = props;

  const dispatch = useDispatch();

  const refreshDialog = useSelector(
    (state) => state?.dialog?.showRefreshDialog
  );

  const headerText = jobSelectedState
    ? "Refresh selected jobs?"
    : "Confirm to refresh?";
  const contentText = jobSelectedState
    ? "Refreshing will replace the current jobs with a new set. Your selections will be lost."
    : "You haven't viewed all the jobs yet. Want to keep browsing? You'll still encounter these jobs as you continue exploring.";

  function handleClose() {
    dispatch(updateRefreshDialogStatus(false));
  }

  function handleRefresh() {
    handleRefreshJobs(false);
    setTimeout(() => {
      dispatch(updateRefreshDialogStatus(false));
    }, 150);
  }

  return (
    <DialogContainer open={refreshDialog} onClose={() => handleClose("X")}>
      <DialogHeader>
        {headerText}
        <CloseButton onClick={() => handleClose("X")}>
          <CloseIcon />
        </CloseButton>
      </DialogHeader>
      <DialogContent>{contentText}</DialogContent>
      <DialogFooter>
        <ButtonStyled onClick={() => handleClose("Back")}>Back</ButtonStyled>
        <ButtonStyled variant="filled" onClick={handleRefresh}>
          Proceed
        </ButtonStyled>
      </DialogFooter>
    </DialogContainer>
  );
}
