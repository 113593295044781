import CheckCircleIcon from "@heroicons/react/20/solid/CheckCircleIcon";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import UploadFailIcon from "../../../assets/svg/resume-rejected.svg";
import { P } from "../styles";

export const ResumeWrapper = styling("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  padding: "0 5rem",
  margin: "3.5rem 0",

  "@media (max-width: 1280px)": {
    padding: "0 1.5rem",
  },

  "@media (max-width: 640px)": {
    padding: "0 1rem",
  },
});

export const HeaderContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  textAlign: "center",

  margin: "0 0 2.5rem",
});

export const CardContainer = styling("div")({
  backgroundColor: Color.white,
  border: "1px solid" + Color.grey,
  borderRadius: "0.5rem",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  maxWidth: "800px",
  width: "100%",

  padding: "2rem 1.5rem 1.5rem",

  "@media (max-width: 640px)": {
    padding: "1.25rem 1rem 1rem",
  },
});

export const DropzoneContainer = styling("div")({
  background: Color.lightGrey,
  border: "1px dashed" + Color.darkGrey,
  borderRadius: "0.625rem",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  width: "100%",
  padding: "0.5rem 0 1rem",
  margin: "1rem 0 1.875rem",

  "@media (max-width: 640px)": {
    padding: "2.125rem 1rem",
    margin: "1.25rem 0 1rem",
  },
});

export const DragResumeElement = styling("div")({
  position: "absolute",
  backgroundColor: "red",
  width: "100%",
  height: "100%",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
  zIndex: 100,
});

export const ResumeInReviewContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  height: "244px",

  "@media (max-width: 1280px)": {
    height: "252px",
  },

  "@media (max-width: 640px)": {
    justifyContent: "flex-start",
    gap: "1.5rem",
    height: "288px",
  },
});

export const ResumeRejectedContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",

  height: "288px",
});

export const ButtonContainer = styling("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",

  width: "100%",
  margin: "2rem 0 0",

  "@media (max-width: 640px)": {
    margin: "1.5rem 0 0",
  },
});

export const HeaderText = styling(P)({
  color: Color.darkPurple,

  fontSize: "1.5625rem",
  fontWeight: "600",
  lineHeight: "32px",

  "@media (max-width: 640px)": {
    fontSize: "1rem",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
});

export const SubheadingText = styling(P)({
  color: Color.black,

  fontSize: "1rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
  },
});

export const CardHeaderText = styling(P)({
  color: Color.darkPurple,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",

  fontSize: "1.25rem",
  fontWeight: "500",
  lineHeight: "24px",

  "@media (max-width: 640px)": {
    fontSize: "1rem",
  },
});

export const CardSubheadingText = styling(P)({
  color: Color.black,

  fontSize: "1rem",
  lineHeight: "20px",
  letterSpacing: "0.15px",
  textAlign: "center",

  width: "500px",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
    letterSpacing: "0.112px",
    width: "100%",
  },
});

export const ResumeFormatText = styling(P)({
  color: "rgba(0, 0, 0, 0.60)",

  fontSize: "0.75rem",
  lineHeight: "20px",

  margin: "0.75rem 0 0",
});

export const ResumeFailedText = styling(P)({
  color: "rgba(0, 0, 0, 0.60)",

  fontSize: "0.75rem",
  fontStyle: "italic",
  lineHeight: "24px",

  margin: "0.75rem 0 0",

  "@media (max-width: 640px)": {
    fontSize: "0.625rem",
    margin: "0",
  },
});

export const LinkStyled = styling("a")({
  color: Color.hiredlyPurple,

  lineHeight: "20px",
});

export const InputStyled = styling("input")({
  display: "none",
});

export const CheckIcon = styling(CheckCircleIcon)({
  color: Color.buttonGreen,
  height: "24px",
  width: "24px",
});

export const ResumeFailed = styling(UploadFailIcon)({
  height: "100px",
  width: "100px",
});
