import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as config from "../../../config/config";
import { updateRecentSearches } from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { store } from "../../../redux/stores/store";
import {
  ImageStyled,
  LinkStyled,
  NoResultsContainer,
  NoResultsTextContainer,
  SearchTipsContainer,
  SubtitleStyled,
  TitleStyled,
} from "./styles";

export default function JobListNoResult() {
  const keyword = useSelector((state) => state.jobs.jobListFilter.keyword);
  const searchSuggestion = useSelector((state) => state.jobs.searchSuggestion);
  const [searchKeyword, setSearchKeyword] = useState(keyword);

  useEffect(() => {
    setSearchKeyword(keyword);
  }, [keyword]);

  function handleSearchSuggestionClicked() {
    let cKeyword;

    if (keyword.constructor === Array) {
      cKeyword = keyword[0];
    } else {
      cKeyword = keyword;
    }

    // For GA tracking
    if (store.getState().jobs.fromHomepageSearchbar) {
      sendTrackingEvent({
        event: "CE_search-main-job-autocorrect",
        "previous-search-term": `${cKeyword}`,
        "search-term": `${searchSuggestion}`,
      });
      store.getState().jobs.fromHomepageSearchbar = false;
    } else if (store.getState().jobs.fromNavSearchbar) {
      sendTrackingEvent({
        event: "CE_search-job-autocorrect-nav-job-list",
        "previous-search-term": `${cKeyword}`,
        "search-term": `${searchSuggestion}`,
      });
      store.getState().jobs.fromNavSearchbar = false;
    } else if (store.getState().jobs.fromJobsListSearchbar) {
      sendTrackingEvent({
        event: "CE_search-job-autocorrect-job-list",
        "previous-search-term": `${cKeyword}`,
        "search-term": `${searchSuggestion}`,
      });
      store.getState().jobs.fromJobsListSearchbar = false;
    } else if (store.getState().jobs.fromJobsListNavBar) {
      sendTrackingEvent({
        event: "CE_search-job-autocorrect-nav-job-list",
        "previous-search-term": `${cKeyword}`,
        "search-term": `${searchSuggestion}`,
      });
      store.getState().jobs.fromJobsListNavBar = false;
    }

    store.getState().jobs.jobListFilter["keyword"] = searchSuggestion;
    if (props.loadingJobs) {
      props.loadingJobs(true);
    }
    updateRecentSearches(searchSuggestion);
  }

  return (
    <NoResultsContainer>
      <ImageStyled
        src={
          config.assetDomain +
          "/images/jobseeker-illustrations/hiredly-job-search-empty-placeholder.svg"
        }
      />
      <NoResultsTextContainer>
        <TitleStyled>
          No results found for <span>{searchKeyword}</span>
        </TitleStyled>
        {searchSuggestion?.length > 0 && keyword !== searchSuggestion ? (
          <TitleStyled>
            Did you mean:{" "}
            <LinkStyled onClick={handleSearchSuggestionClicked}>
              {searchSuggestion}
            </LinkStyled>
          </TitleStyled>
        ) : (
          <SearchTipsContainer>
            <SubtitleStyled>
              <span>Search Tips:</span>
            </SubtitleStyled>
            <ul>
              <li>Ensure that the search terms are spelled correctly</li>
              <li>Try different search terms</li>
              <li>Adjust your filters to widen your search</li>
            </ul>
          </SearchTipsContainer>
        )}
      </NoResultsTextContainer>
    </NoResultsContainer>
  );
}
