import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";
import EyeSlashIcon from "@heroicons/react/24/outline/EyeSlashIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import BookmarkFilledIcon from "@heroicons/react/24/solid/BookmarkIcon";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import { P } from "../styles";

export const CardWrapper = styling("div")({
  position: "relative",

  display: "flex",
  flex: "0 0 auto",

  maxWidth: "249px",
  width: "100%",
  height: "100%",
  overflow: "clip",
  scrollSnapAlign: "center",
  scrollSnapStop: "always",

  "@media (max-width: 1280px)": {
    maxWidth: "320px",
    height: "520px",
  },

  "@media (max-width: 640px)": {
    maxWidth: "296px",
    height: "100%",
  },
});

export const HideJobContainer = styling("div")({
  position: "absolute",

  background:
    "linear-gradient(180deg, rgba(250, 250, 250, 0.00) 0%, rgba(250, 250, 250, 0.97) 45%, #FAFAFA 100%)",
  backdropFilter: "blur(5px)",
  border: "1px dashed" + Color.secondaryGrey,
  borderRadius: "0.75rem",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",

  height: "100%",
  width: "100%",
  padding: "1rem",

  zIndex: 3,
});

export const CardContainer = styling("div")((props) => ({
  backgroundColor: "rgba(247, 247, 255, 1)",
  border: "1px solid" + Color.secondaryGrey,
  borderRadius: "0.75rem",

  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  height: "428px",
  width: "100%",
  overflow: "clip",

  transition: "all 0.15s ease-in-out",

  "& .hover-check": {
    transition: "all 0.15s ease-in-out",
    color: "transparent",
  },

  ...(props?.selected && {
    border: "1px solid" + Color.hiredlyPurple,
    //boxShadow: "0px 0px 0px 1px " + Color.hiredlyPurple,
  }),

  "&:hover": {
    border: "1px solid" + Color.hiredlyPurple,
    cursor: "pointer",

    "& .hover-check": {
      color: props?.selected ? Color.white : Color.black,
    },
  },

  "@media (max-width: 1280px)": {
    height: "520px",
  },

  "@media (max-width: 640px)": {
    height: "100%",
  },
}));

export const ImageHeaderContainer = styling("div")({
  position: "relative",
  height: "88px",
});

export const JobHeaderContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",

  padding: "0.625rem",

  "@media (max-width: 640px)": {
    padding: "0.5rem 0.625rem",
  },
});

export const FooterContainer = styling("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  height: "52px",
  padding: "0.5rem",
});

export const DescriptionContainer = styling("div")({
  padding: "0.25rem 0.375rem 0.25rem 0.625rem",
});

export const ScrollContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",

  height: "100%",
  maxHeight: "178px",
  width: "100%",
  overflowY: "auto",
  overflowX: "hidden",

  padding: "0.25rem 0.5rem 0 0",

  scrollBehavior: "smooth",

  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "transparent",
    width: 4,
    overflow: "auto",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 64,
    backgroundColor: Color.hiredlyPurple,
    overflow: "auto",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: 64,
    backgroundColor: Color.grey,
  },

  "@media (max-width: 1280px)": {
    maxHeight: "270px",
    minHeight: "270px",
  },

  "@media (max-width: 640px)": {
    minHeight: "136px",
    height: "calc(100svh - 428px - 250px)",
  },
});

export const DetailContainer = styling("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "0.25rem",
});

export const AiSummaryContainer = styling("div")({
  color: "rgba(0, 0, 0, 0.87)",
  backgroundColor: Color.white,
  border: "1px solid" + Color.lightGrey,
  borderRadius: "0.375rem",

  display: "flex",
  flexDirection: "column",
  gap: "0.375rem",

  padding: "0.5rem 0.75rem",
});

export const JobTitleText = styling(P)({
  color: "rgba(0, 0, 0, 0.87)",

  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",

  fontSize: "0.875rem",
  fontWeight: 600,
  lineHeight: "24px",
  textOverflow: "ellipsis",

  height: "48px",
  margin: "0",
  overflow: "hidden",
});

export const CompanyNameText = styling(P)({
  color: "rgba(0, 0, 0, 0.87)",

  fontSize: "0.625rem",
  lineHeight: "20px",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",

  margin: "0",
});

export const DetailChip = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "0.25rem",

  fontSize: "0.625rem",
  fontWeight: "700",
  whiteSpace: "nowrap",

  height: "1.3125rem",
  padding: "0 0.375rem",

  ...(props.type === "jobType" && {
    color: Color.blue,
    backgroundColor: "rgba(33, 61, 181, 0.14)",
  }),
  ...(props.type === "state" && {
    color: "rgba(129, 155, 72, 1)",
    backgroundColor: "rgba(129, 155, 72, 0.14)",
  }),
  ...(props.type === "salary" && {
    color: "rgba(155, 72, 72, 1)",
    backgroundColor: "rgba(155, 72, 72, 0.14)",
  }),
}));

export const HideButtonContainer = styling("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "0.375rem",

  margin: "1.5rem 0 0",

  "& > *:only-child": {
    marginLeft: "auto",
  },
});

export const AiSummaryHeaderText = styling(P)({
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: "0.75rem",
  fontWeight: 600,
  lineHeight: "20px",
});

export const AiSummaryText = styling(P)({
  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "0.75rem",
  lineHeight: "20px",
});

export const JobDetailsText = styling(P)((props) => ({
  border: "1px solid" + Color.lightGrey,
  borderRadius: "0.375rem",

  fontSize: "0.75rem",
  fontWeight: 400,
  lineHeight: "20px",

  padding: "0.375rem 0.5rem",

  ...(props?.type === "skills" && {
    height: "100%",
    background: "#F7FFFA",
  }),

  ...(props?.type === "benefits" && {
    height: "100%",
    background: "#FFFEF7",
  }),
}));

export const ShowMoreText = styling("span")({
  fontWeight: "700",
  cursor: "pointer",
});

export const HideHeaderText = styling(P)({
  color: "rgba(0, 0, 0, 0.87)",

  display: "flex",
  alignItems: "center",
  gap: "0.375rem",

  fontSize: "0.875rem",
  fontWeight: 700,
  lineHeight: "24px",
  letterSpacing: "0.1px",
});

export const HideContentText = styling(P)({
  color: "rgba(0, 0, 0, 0.87)",

  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.15px",
});

export const MoreDetailButton = styling("span")({
  color: Color.hiredlyPurple,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  fontSize: "0.875rem",
  fontWeight: 700,
  lineHeight: "24px",
  textDecoration: "none",

  height: "36px",
  padding: "0 0.5rem",

  "&:hover": {
    cursor: "pointer",
  },

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
  },
});

export const BookmarkButton = styling(ButtonBase)({
  position: "absolute",
  top: "0.625rem",
  right: "0.625rem",

  borderRadius: "0.25rem",

  color: Color.hiredlyPurple,

  zIndex: 2,

  "&:hover": {
    cursor: "pointer",
    "& *": {
      color: "rgba(231, 227, 244, 1)",
    },
  },
});

export const HideButton = styling(ButtonBase)({
  color: Color.darkGrey,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.375rem",

  fontFamily: "Inter",
  fontSize: "0.75rem",
  fontWeight: 500,
  letterSpacing: "0.055px",

  height: "36px",
  padding: "0 0.5rem",
});

export const TextButton = styling(ButtonBase)((props) => ({
  color: "rgba(0, 0, 0, 0.60)",

  fontFamily: "Inter",
  fontSize: "0.75rem",
  fontWeight: 700,
  lineHeight: "24px",
  letterSpacing: "0.055px",

  height: "24px",
  ...(props.color === "purple" && {
    color: Color.hiredlyPurple,
  }),
}));

export const OptionContainer = styling(RadioGroup)({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
});

export const RadioStyled = styling(Radio)({
  height: "16px",
  width: "16px",
  "& .MuiSvgIcon-root": {
    fontSize: 16,
  },
});

export const OptionLabel = styling(FormControlLabel)({
  gap: "0.5rem",
  margin: "0",

  span: {
    color: "rgba(0, 0, 0, 0.87)",

    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
});

export const HeaderImage = styling("img")({
  objectFit: "cover",
  objectPosition: "center",

  backgroundColor: Color.white,

  height: "88px",
  width: "100%",

  zIndex: 1,

  userSelect: "none",
});

export const CompanyLogoImage = styling("img")({
  objectFit: "contain",
  objectPosition: "center",

  border: "1px solid" + Color.secondaryGrey,
  borderRadius: "0.25rem",

  height: "28px",
  width: "28px",
  overflow: "clip",
});

export const DividerStyled = styling(Divider)({});

export const SaveIcon = styling(BookmarkFilledIcon)({
  color: Color.white,
  stroke: Color.hiredlyPurple,
  strokeWidth: "1.5",

  height: "24px",
  width: "24px",

  transition: "all 0.15s ease-in-out",
  "&:hover": {
    strokeWidth: "2",
  },
});

export const UnsaveIcon = styling(BookmarkFilledIcon)({
  height: "24px",
  width: "24px",
  strokeWidth: "1.5",

  transition: "all 0.15s ease-in-out",

  "&:hover": {
    strokeWidth: "2",
  },
});

export const HideIcon = styling(EyeSlashIcon)({
  height: "16px",
  width: "16px",
});

export const CloseIcon = styling(XMarkIcon)({
  color: Color.black,
  height: "16px",
  width: "16px",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    color: Color.darkGrey,
  },
});

export const LeftArrowIcon = styling(ArrowLeftIcon)({
  height: "20px",
  width: "20px",
});
