import ChevronDownIcon from "@heroicons/react/16/solid/ChevronDownIcon";
import InformationCircleIcon from "@heroicons/react/20/solid/InformationCircleIcon";
import MagnifyingGlassIcon from "@heroicons/react/20/solid/MagnifyingGlassIcon";
import SparklesIcon from "@heroicons/react/20/solid/SparklesIcon";
import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import { styled as styling } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ToastContainer } from "react-toastify";
import Color from "../../../assets/colors";

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const Wrapper = styling("div")((props) => ({
  backgroundColor: Color.backgroundGrey,
  height: props.selectedTab === 0 ? "calc(100svh - 64px)" : "100%",
  marginTop: props.alertBanner ? "64px" : 0,

  "@media (max-width: 1279px)": {
    height: props.selectedTab === 0 ? "calc(100svh - 52px)" : "100%",
    marginTop: props.alertBanner ? "52px" : 0,
  },

  "@media (max-width: 839px)": {
    height: props.selectedTab === 0 ? "calc(100svh - 82px)" : "100%",
    marginTop: props.alertBanner ? "82px" : 0,
  },

  "@media (max-width: 640px)": {
    height: props.selectedTab === 0 ? "calc(100svh - 62px)" : "100%",
    marginTop: props.alertBanner ? "104px" : 0,

    "@media (max-height: 700px)": {
      height: props.selectedTab === 0 ? "calc(100svh - 14px)" : "100%",
      marginTop: props.alertBanner ? "104px" : 0,
    },
  },
}));

export const TabNavWrapper = styling("div")((props) => ({
  position: "sticky",
  top: props.alertBanner ? "calc(64px + 64px)" : "64px",
  zIndex: "10",

  backgroundColor: Color.white,
  borderBottom: "2px solid " + Color.black,

  display: "flex",
  alignItems: "center",

  "@media (max-width: 1279px)": {
    top: props.alertBanner ? "calc(52px + 52px)" : "52px",
  },

  "@media (max-width: 839px)": {
    top: props.alertBanner ? "calc(52px + 82px)" : "52px",
  },

  "@media (max-width: 639px)": {
    top: props.alertBanner ? "calc(60px + 104px)" : "60px",
  },
}));

export const TabWrapper = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",

  width: "100%",
  maxWidth: "1440px",
  padding: "0 5rem",
  margin: "0 auto -2px",

  "@media (max-width: 1279px)": {
    maxWidth: "916px",
    padding: "0 1rem",
    margin: "0 auto -2px",
  },
}));

export const TabGroup = styling("div")({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-start",
  gap: "0.375rem",

  height: "4.25rem",
  width: "100%",

  "@media (max-width: 1279px)": {
    height: "3.25rem",
  },

  "@media (max-width: 640px)": {
    justifyContent: "center",
  },
});

export const TabContainer = styling("div")((props) => ({
  position: "relative",

  display: "flex",
  alignItems: "center",

  height: "56px",
  width: "100%",
  maxWidth:
    props?.selectedTab === 1 && props.currentTab === 1
      ? "728px" //32px(paddingLeft+Right) + 700px(component inside tab)
      : props?.selectedTab === 0 && props.currentTab === 0
      ? "162px"
      : "160px",
  margin: "0 0",

  overflowX: "clip",

  cursor: "pointer",
  transition:
    "all 0.2s ease-in-out, color 0.1s ease-in-out, font-weight 0.1s ease-in-out, text-decoration 0.2s ease-in-out, max-width 0.1s ease-in-out",

  ...(props.$disabled && {
    cursor: "not-allowed",
    pointerEvents: "none",
  }),

  "@media (max-width: 1279px)": {
    justifyContent: "center",
    maxWidth: "160px",
    height: "44px",
  },
}));

export const TabContent = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",

  width: "100%",
  padding: "0.625rem 1rem",
  zIndex: 1,

  "@media (max-width: 1279px)": {
    justifyContent: "center",
    padding: "0.625rem 0.875rem 0.625rem 0.625rem",
  },
}));

export const TabTitle = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.75rem",

  fontSize: "1rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textWrap: "nowrap",
  whiteSpace: "nowrap",

  transition: "all 0.05s ease-in-out",

  ...(props.selected && {
    color: Color.hiredlyPurple,
    fontWeight: "700",
    textDecoration: "none",
  }),

  "@media (min-width: 1280px)": {
    "&:hover": {
      fontWeight: "700",
      textDecoration: "underline",
    },
  },

  "@media (max-width: 1279px)": {
    fontSize: "0.875rem",
  },
}));

export const TabSlider = styling("div")((props) => ({
  position: "absolute",
  bottom: "0",

  borderRadius: "0.5rem 0.5rem 0 0",

  height: "100%",
  width: "0",

  transition: "all 0.15s ease-in-out, width 0.05s ease-in-out",

  ...(props.selected && {
    width: "100%",
    backgroundColor: Color.backgroundGrey,
    borderTop: "2px solid" + Color.black,
    borderLeft: "2px solid" + Color.black,
    borderRight: "2px solid" + Color.black,
  }),
}));

export const ForYouOptionsContainer = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",

  width: "100%",
  margin: "0 0 0 1rem",

  "@media (max-width: 1279px)": {
    justifyContent: "space-between",

    maxWidth: "972px",
    padding: "1rem 1.5rem 0",
    margin: "0 auto",
  },

  "@media (max-width: 640px)": {
    padding: "1rem 1rem 0",
  },
}));

export const JobListFiltersContainer = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",

  width: "100%",
  margin: "0 0 0 1rem",

  "@media (max-width: 1279px)": {
    justifyContent: "space-between",

    maxWidth: "972px",
    padding: "1rem 1.5rem 0",
    margin: "0 auto",
  },

  "@media (max-width: 640px)": {
    padding: "1rem 1rem 0",
  },
}));

export const ButtonStyled = styling(ButtonBase)((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",

  color: props.selected ? "rgba(115, 95, 180, 1)" : Color.darkGrey,
  backgroundColor: props.selected ? Color.lightHiredlyPurple : Color.white,
  border: props.selected
    ? `1px solid rgba(115, 95, 180, 1)`
    : `1px solid ${Color.secondaryGrey}`,
  boxShadow: props.selected ? `0px 0px 0px 1px rgba(115, 95, 180, 1)` : "none",
  borderRadius: "0.5rem",

  fontFamily: "Inter",
  fontSize: "0.875rem",
  lineHeight: "1.5rem",
  letterSpacing: "0.15px",
  whiteSpace: "nowrap",

  height: "2.25rem",
  padding: "0 0.625rem",

  transition:
    "all 0.15s ease-in-out, border 0.025s ease-in-out, box-shadow 0.175s ease-in-out",

  "@media (min-width: 1280px)": {
    "&:hover": {
      color: props.selected ? Color.darkPurple : Color.black,
      backgroundColor: props.selected ? "#EFEFFD" : Color.white,
      border: props.selected ? "1px solid #EFEFFD" : `1px solid ${Color.white}`,
      boxShadow: props.selected
        ? "0px 0px 0px 2px" + Color.darkPurple
        : "0px 0px 0px 1.5px rgba(0, 0, 0, 0.68)",

      "* > .MuiSwitch-track": {
        backgroundColor: "rgba(0, 0, 0, 0.68)",
      },
    },
  },

  "@media (max-width: 1279px)": {
    height: "2.5rem",
  },

  "@media (max-width: 640px)": {
    flexGrow: "1",
    height: "2.25rem",
    minWidth: "fit-content",
    width: "50%",
  },
}));

export const TextStyled = styling(Typography)({});

//Tooltip Component//
export const TooltipStyled = styling(Tooltip)({});

// Select Component //
export const SelectContainer = styling("div")({
  width: "100%",
  maxWidth: "160px",

  "@media (max-width: 640px)": {
    maxWidth: "100%",
  },
});

export const SelectHeader = styling(Box)((props) => ({
  color: "rgba(0, 0, 0, 0.38)",
  backgroundColor: Color.white,
  border: `1px solid ${Color.grey}`,
  borderRadius: "0.5rem",

  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0.5rem",
  // flexWrap: "nowrap",

  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  letterSpacing: "0.112px",
  whiteSpace: "nowrap",

  padding: "0.5rem 0.625rem",

  transition: "all 0.15s ease-in-out",

  ...(props.active && {
    color: Color.black,
    border: `1px solid ${Color.hiredlyPurple}`,
  }),

  ...(props.$disabled && {
    pointerEvents: "none",
    opacity: "0.5",
    cursor: "not-allowed",
  }),

  "&:hover": {
    cursor: "pointer",
  },

  "@media (max-width: 640px)": {
    height: "2.25rem",
  },
}));

export const SelectDropdown = styling("div")((props) => ({
  position: "absolute",
  zIndex: "10",

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  backgroundColor: Color.white,
  border: "2px solid transparent",
  borderRadius: "0.5rem",

  width: "100%",
  maxWidth: "160px",
  margin: "0.25rem 0 0 0",

  opacity: "0",
  pointerEvents: "none",
  transition:
    "max-height 0.2s ease-in, border 0.15s ease-in-out, opacity 0.15s ease-in-out, margin-top 0.15s ease-in-out",
  transform: "translate3d(0,0,0)",

  ...(props.open && {
    border: "2px solid" + Color.black,
    pointerEvents: "auto",
    opacity: "1",
  }),

  "@media (max-width: 640px)": {
    flexGrow: "1",
  },
}));

export const SelectOption = styling(Button)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",

  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "0.875rem",

  lineHeight: "1.25rem",
  letterSpacing: "0.112px",
  textTransform: "initial",
  textAlign: "left",
  textWrap: "nowrap",

  height: "2.25rem",
  width: "100%",

  padding: "0 1rem",

  "&:hover": {
    backgroundColor: Color.lightHiredlyPurple,
  },
}));

export const FypIcon = styling(SparklesIcon)({
  height: "20px",
  width: "20px",
});

export const SearchIcon = styling(MagnifyingGlassIcon)({
  height: "20px",
  width: "20px",
});

export const HistoryIcon = styling(ClockIcon)({
  height: "20px",
  width: "20px",
});

export const InfoIcon = styling(InformationCircleIcon)({
  height: "16px",
  width: "16px",
});

export const DownArrowIcon = styling(ChevronDownIcon)((props) => ({
  color: props.active ? Color.hiredlyPurple : Color.darkGrey,

  height: "20px",
  width: "20px",

  margin: "0 -4px 0 0",

  transform: props.selected ? "rotate(-180deg)" : "rotate(0deg)",
  transition: "all 0.15s ease-in-out",
}));

export const UploadedResumeToastStyled = styling(ToastContainer)({
  "&.Toastify__toast-container": {
    width: "320px",
    "& .Toast-success": {
      borderLeft: `5px solid #90B938`,
      color: "#90B938",
    },

    "& .Toast-error": {
      borderLeft: `5px solid #F75443`,
      color: "#F75443",
    },
  },

  "& .Toastify__toast": {
    minHeight: "36px",
    borderRadius: "8px",
    backgroundColor: Color.white,
    boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.20)",
    padding: "8px 16px",

    "& .Toastify__close-button": {
      color: "#00000099",
      opacity: 1,
      position: "relative",
      padding: 0,
      marginTop: "10px",
    },

    "& .Toastify__progress-bar--wrp": {
      opacity: 0,
    },
  },
});

export const ToastTitleContainer = styling(Grid)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

export const ToastTitle = styling(Typography)({
  color: "#000000",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
});

export const StyledCheckCircle = styling(CheckCircleIcon)({
  width: "15px",
  height: "15px",
  color: "#90B938",
});

export const ToastContentContainer = styling(Grid)({
  marginTop: "8px",
});

export const ToastContentText = styling(Typography)({
  color: "#00000099",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
});
