import {
  CardWrapper,
  ChipContainer,
  DescriptionContainer,
  JobHeaderContainer,
  SkeletonCard,
} from "./styles";

export default function JobCardSkeleton() {
  return (
    <CardWrapper>
      <SkeletonCard variant="rectangular" height="100px" width="100%" />
      <JobHeaderContainer>
        <SkeletonCard
          width="100%"
          height="28px"
          sx={{ borderRadius: "6.25rem" }}
        />
        <SkeletonCard
          width="75%"
          height="28px"
          sx={{ borderRadius: "6.25rem" }}
        />
      </JobHeaderContainer>
      <DescriptionContainer>
        <ChipContainer>
          <SkeletonCard
            width="72px"
            height="20px"
            sx={{ borderRadius: "6.25rem" }}
          />
          <SkeletonCard
            width="72px"
            height="20px"
            sx={{ borderRadius: "6.25rem" }}
          />
          <SkeletonCard
            width="72px"
            height="20px"
            sx={{ borderRadius: "6.25rem" }}
          />
        </ChipContainer>
        <SkeletonCard
          width="100%"
          height="20px"
          sx={{ borderRadius: "6.25rem" }}
        />
        <SkeletonCard
          width="100%"
          height="20px"
          sx={{ borderRadius: "6.25rem" }}
        />
        <SkeletonCard
          width="75%"
          height="20px"
          sx={{ borderRadius: "6.25rem" }}
        />
      </DescriptionContainer>
    </CardWrapper>
  );
}
