import Skeleton from "@mui/material/Skeleton";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const CardWrapper = styling("div")({
  backgroundColor: Color.white,
  borderRadius: "0.75rem",

  display: "flex",
  flex: "0 0 auto",
  flexDirection: "column",

  height: "426px",
  minHeight: 0,
  maxWidth: "250px",
  width: "100%",
  overflow: "auto",
  scrollSnapAlign: "center",

  "@media (max-width: 1280px)": {
    maxWidth: "320px",
  },

  "@media (max-width: 640px)": {
    maxWidth: "296px",
  },
});

export const ImageHeaderContainer = styling("div")({});

export const JobHeaderContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.375rem",

  padding: "0.625rem",
});

export const DescriptionContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",

  padding: "1rem 0.375rem 1rem 0.625rem",
});

export const ChipContainer = styling("div")({
  display: "flex",
  gap: "0.25rem",
});

export const SkeletonCard = styling(Skeleton)({
  transform: "none",
});
