import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import ButtonBase from "@mui/material/ButtonBase";
import Dialog from "@mui/material/Dialog";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const DialogContainer = styling(Dialog)({
  "& .MuiDialog-container .MuiPaper-root": {
    border: "1.5px solid " + Color.black,
    borderRadius: "1.5rem",
    overflow: "clip",

    maxHeight: "240px",
    maxWidth: "420px",

    transition: "all 0.3s ease-in-out",

    "@media (max-width: 640px)": {
      maxWidth: "328px",
    },
  },
});

export const DialogHeader = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  borderBottom: "1px solid" + Color.grey,

  fontSize: "1.25rem",
  fontWeight: "700",
  lineHeight: "24px",

  padding: "1rem 1.5rem",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
    padding: "0.75rem 0.75rem 0.5rem",
  },
});

export const DialogContent = styling("div")({
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  padding: "1rem 1.5rem",
  margin: "1rem 0",

  "@media (max-width: 1280px)": {
    margin: "0",
  },

  "@media (max-width: 640px)": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    fontSize: "0.875rem",
    lineHeight: "20px",
    letterSpacing: "0.112px",
    height: "84px",
    padding: "0.5rem 1rem 1rem",
  },
});

export const DialogFooter = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1rem",

  backgroundColor: Color.white,
  borderTop: "1px solid" + Color.grey,

  padding: "1rem",

  // Add these properties to make it sticky
  position: "sticky",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
});

export const ButtonStyled = styling(ButtonBase)((props) => ({
  borderRadius: "6.25rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: "700",

  height: "42px",
  padding: "0 2rem",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
    height: "36px",
  },

  ...(props?.variant === "filled" && {
    color: Color.white,
    backgroundColor: Color.black,
  }),
}));

export const CloseButton = styling(ButtonBase)({
  fontSize: "1.25rem",
  fontWeight: "600",
});

export const CloseIcon = styling(XMarkIcon)({
  width: "24px",
  height: "24px",
});
