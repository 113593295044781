import { useMemo } from "react";
import * as config from "../../../config/config";
import { BoxContainer, CheckboxButton, CheckboxContainer } from "../styles";
import {
  AiSummaryContainer,
  AiSummaryHeaderText,
  AiSummaryText,
  BookmarkButton,
  CardContainer,
  CardWrapper,
  CompanyLogoImage,
  CompanyNameText,
  DescriptionContainer,
  DetailChip,
  DetailContainer,
  DividerStyled,
  FooterContainer,
  HeaderImage,
  HideButton,
  HideIcon,
  ImageHeaderContainer,
  JobDetailsText,
  JobHeaderContainer,
  JobTitleText,
  MoreDetailButton,
  SaveIcon,
  ScrollContainer,
} from "./styles";

export default function ForYouJobCard() {
  const jobTitle = useMemo(() => generateJobTitle(), []);

  function generateJobTitle() {
    const firstWord = [
      "Software",
      "Business",
      "Marketing",
      "Sales",
      "Finance",
      "HR",
      "Legal",
      "Engineering",
    ];

    const secondWord = [
      "Developer",
      "Manager",
      "Analyst",
      "Specialist",
      "Consultant",
    ];

    const randomFirstWord =
      firstWord[Math.floor(Math.random() * firstWord.length)];
    const randomSecondWord =
      secondWord[Math.floor(Math.random() * secondWord.length)];

    return `${randomFirstWord} ${randomSecondWord}`;
  }

  const mockJobDetail = {
    companyName: "Hiredly",
    companyLogo: config.assetDomain + "/images/landing/hiredly-logo.png",
    jobType: "Full-time",
    stateRegion: "Kuala Lumpur",
    salaryRange: "RM 10,000 - RM 12,000",
    gptSummary:
      "This is a summary of the job description, something that is generated by AI, very interesting text thingy",
    skills: ["JavaScript", "React", "Node.js"],
    benefits: ["Health insurance", "Dental insurance", "Vision insurance"],
  };

  return (
    <CardWrapper>
      <CardContainer>
        <BoxContainer flexDirection="column">
          <ImageHeaderContainer>
            <BookmarkButton>
              <SaveIcon />
            </BookmarkButton>
            <HeaderImage
              src={mockJobDetail.companyLogo}
              alt={mockJobDetail.companyName}
            />
          </ImageHeaderContainer>

          <JobHeaderContainer>
            <BoxContainer
              justifyContent="space-between"
              alignItems="flex-start"
              gap={"0.5rem"}
            >
              <JobTitleText>{jobTitle}</JobTitleText>
              <CheckboxContainer>
                <CheckboxButton />
              </CheckboxContainer>
            </BoxContainer>

            <BoxContainer gap="0.5rem" alignItems="center">
              <CompanyLogoImage
                src={mockJobDetail.companyLogo}
                alt={mockJobDetail.companyName}
              />
              <CompanyNameText>{mockJobDetail.companyName}</CompanyNameText>
            </BoxContainer>
          </JobHeaderContainer>

          <DescriptionContainer>
            <ScrollContainer>
              <DetailContainer>
                <DetailChip type="jobType">{mockJobDetail.jobType}</DetailChip>
                <DetailChip type="state">
                  {mockJobDetail.stateRegion}
                </DetailChip>
                <DetailChip type="salary">
                  {mockJobDetail.salaryRange}
                </DetailChip>
              </DetailContainer>

              <AiSummaryContainer>
                <AiSummaryHeaderText>AI-Generated Summary:</AiSummaryHeaderText>
                <DividerStyled />
                <AiSummaryText>{mockJobDetail.gptSummary}</AiSummaryText>
              </AiSummaryContainer>

              {mockJobDetail.skills?.length > 0 && (
                <JobDetailsText type="skills">
                  <b>Skills: </b>
                  {mockJobDetail.skills}
                </JobDetailsText>
              )}

              {mockJobDetail.benefits?.length > 0 && (
                <JobDetailsText type="benefits">
                  <b>Benefits: </b>
                  {mockJobDetail.benefits}
                </JobDetailsText>
              )}
            </ScrollContainer>
          </DescriptionContainer>
        </BoxContainer>

        <FooterContainer>
          <HideButton>
            Hide
            <HideIcon />
          </HideButton>
          <MoreDetailButton>View Full Details</MoreDetailButton>
        </FooterContainer>
      </CardContainer>
    </CardWrapper>
  );
}
