import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateForYouResumeDialogStatus,
  updatePostApplyDialog,
  updateRefreshDialogStatus,
} from "../../../redux/actions/dialog_action";
import {
  bulkApplyJobs,
  updateJobList,
} from "../../../redux/actions/job_action";
import ForYouJobCard from "../ForYouJobCard/ForYouJobCard";
import ForYouResumeDialog from "../ForYouResumeDialog/ForYouResumeDialog";
import RefreshDialog from "../RefreshDialog/RefreshDialog";
import JobCardSkeleton from "../Skeleton/JobCardSkeleton";
import {
  BoxContainer,
  ButtonText,
  CheckboxButton,
  CheckboxContainer,
  CheckboxHover,
  CheckboxIcon,
  CheckedBox,
  RoundedButton,
  UncheckedBox,
} from "../styles";
import VisitorJobCard from "../VisitorJobCard/VisitorJobCard";
import {
  FooterContainer,
  HeaderButtonContainer,
  HeaderContainer,
  HeaderText,
  JobListContainer,
  JobListWrapper,
  RefreshIcon,
  ResumeIcon,
  ScrollIndicator,
  ScrollIndicatorContainer,
  SquareButton,
} from "./styles";

export default function ForYouJobList(props) {
  const { isVisitor, jobDiscovery } = props;

  const jobListRef = useRef(null);
  const toastQueue = useRef([]);

  const dispatch = useDispatch();
  const router = useRouter();

  const isDesktop = useMediaQuery("(min-width: 1280px)");

  const jobs = useSelector((state) => state.jobs?.jobs);
  const isLoadingJobs = useSelector((state) => state.jobs.isLoadingJobs);
  const fetchingJobs = useSelector((state) => state.jobs.fetchingJobList);
  const refreshDialog = useSelector(
    (state) => state?.dialog?.showRefreshDialog
  );

  const [selectedJob, setSelectedJob] = useState([]);
  const [inView, setInView] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [viewedCards, setViewedCards] = useState(new Set());

  const loadingJobs =
    (fetchingJobs || isLoadingJobs || refreshLoading) && !isVisitor;

  const selectAllJobsState = jobs?.length === selectedJob?.length;
  const jobSelectedState = selectedJob?.length > 0;

  const disableSelectedButton = jobSelectedState ? false : true;
  const selectAllText = jobSelectedState ? "Deselect All" : "Select All";
  const selectedText = selectAllJobsState
    ? "Apply to All"
    : `Apply to Selected (${selectedJob?.length})`;

  const RefreshButtonText = loadingJobs ? "Refreshing..." : "Refresh Jobs";

  const startIndex = currentPage * 5;
  const filteredJobs = jobs?.slice(startIndex, startIndex + 5);

  function actionButton({ closeToast, data }) {
    function handleTriggerAction() {
      data.action();
      closeToast(true);
    }

    return (
      <BoxContainer justifyContent={"space-between"} alignItems={"center"}>
        <span>Jobs refreshed</span>
        <ButtonText onClick={handleTriggerAction}>Undo</ButtonText>
      </BoxContainer>
    );
  }

  function handleSelectJob(job) {
    const jobExists = selectedJob?.some((selected) => selected?.id === job?.id);
    if (jobExists) {
      setSelectedJob(
        selectedJob?.filter((selected) => selected?.id !== job?.id)
      );
    } else {
      setSelectedJob([...selectedJob, job]);
    }
  }

  function handleSelectButton(e) {
    e.stopPropagation();
    e.preventDefault();

    if (jobSelectedState) {
      handleDeselectAll();
    } else {
      handleSelectAll();
    }
  }

  function handleSelectAll() {
    setSelectedJob(filteredJobs);

    const cJobsIds = filteredJobs?.map((job) => job.id);
    sendTrackingEvent({
      event: "CE_bulk_job_select_all",
      origin: "fyp_jlp",
      job_ids: { ...cJobsIds },
      timestamp: new Date().toISOString(),
      is_selected: "true",
      selection_type: "select all",
    });
  }

  function handleDeselectAll() {
    const cJobsIds = selectedJob?.map((job) => job.id);
    sendTrackingEvent({
      event: "CE_bulk_job_deselect_all",
      origin: "fyp_jlp",
      job_ids: { ...cJobsIds },
      timestamp: new Date().toISOString(),
      is_selected: "false",
      selection_type: "deselect all",
    });
    setSelectedJob([]);
  }

  function handleResume() {
    dispatch(updateForYouResumeDialogStatus(true));
  }

  function handleUndoRefresh() {
    if (currentPage < 0) {
      return;
    }

    setRefreshLoading(true);

    setCurrentPage(currentPage);
    setSelectedJob([]);

    if (toastQueue.current.length >= 2) {
      const oldestToast = toastQueue.current.shift();
      if (oldestToast) toast.dismiss(oldestToast);
    }

    const newToast = toast.success("Jobs reverted.", {
      timer: 5000,
    });

    toastQueue.current.push(newToast);

    setTimeout(() => {
      setRefreshLoading(false);
    }, 300);
  }

  function handleRefreshJobs(fromDialog = false) {
    if (selectedJob?.length > 0 && !refreshDialog) {
      dispatch(updateRefreshDialogStatus(true));
      return;
    }

    if (!isDesktop && !checkAllCardsViewed() && fromDialog) {
      toast.dismiss();
      dispatch(updateRefreshDialogStatus(true));
      return;
    }

    if (jobs?.length > 0) {
      const nextPage = (currentPage + 1) % Math.ceil(jobs.length / 5);

      if (nextPage === 0) {
        router.reload();
        return;
      }

      setRefreshLoading(true);
      setSelectedJob([]);

      if (jobListRef.current) {
        jobListRef.current.style.scrollBehavior = "auto";
        jobListRef.current.scrollLeft = 0;
        setTimeout(() => {
          if (jobListRef.current) {
            jobListRef.current.style.scrollBehavior = "smooth";
          }
        }, 0);
      }

      setCurrentPage(nextPage);

      if (toastQueue.current.length >= 2) {
        const oldestToast = toastQueue.current.shift();
        if (oldestToast) toast.dismiss(oldestToast);
      }

      const newToast = toast.success(actionButton, {
        autoClose: 8000,
        data: {
          action: handleUndoRefresh,
        },
      });

      toastQueue.current.push(newToast);

      setTimeout(() => {
        setRefreshLoading(false);
      }, 300);
    }
  }

  function handleApplyToSelected() {
    const jobIds = selectedJob.map((job) => job.id);

    const params = {
      jobIds: jobIds,
    };

    dispatch(bulkApplyJobs(params)).then((res) => {
      if (res.success) {
        setSelectedJob([]);

        sendTrackingEvent({
          event: "CE_job_bulk_apply",
          from_job: null,
          job_discovery: "fyp_page",
          num_jobs_apply: selectedJob?.length,
          job_ids: jobIds,
        });

        dispatch(
          updatePostApplyDialog({
            status: true,
            scraped: selectedJob?.[0]?.category === "scraped",
            jobGlobalId: selectedJob?.[0]?.globalId,
          })
        );
      }
    });
  }

  function handleHideJob(job) {
    if (selectedJob.some((selected) => selected.id === job.id)) {
      setSelectedJob(selectedJob.filter((selected) => selected.id !== job.id));
    }

    const updatedJobs = jobs?.filter((j) => j.id !== job.id);

    dispatch(updateJobList(updatedJobs));

    if (filteredJobs.length === 1 && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  function checkAllCardsViewed() {
    if (!isDesktop && filteredJobs?.length > 0) {
      return filteredJobs.every((_, index) => viewedCards.has(index));
    }
    return true;
  }

  function handleSetInView(index) {
    setInView(index);
    setViewedCards((prev) => {
      const newSet = new Set(prev);
      newSet.add(index);
      return newSet;
    });
  }

  useEffect(() => {
    if (jobListRef.current) {
      jobListRef.current.scrollLeft = 0;
    }

    setViewedCards(new Set());
  }, [currentPage]);

  useEffect(() => {
    if (jobs?.length > 0 && !loadingJobs) {
      handleSelectAll();
    }
  }, [jobs, loadingJobs]);

  return (
    <>
      <JobListWrapper>
        <HeaderContainer>
          <HeaderText>
            Here are handpicked roles based on your resume. Refresh to cycle
            through your job recommendation.
          </HeaderText>
          <HeaderButtonContainer>
            <SquareButton onClick={handleResume} disabled={loadingJobs}>
              My Resume
              <ResumeIcon />
            </SquareButton>
            <SquareButton
              onClick={handleSelectButton}
              active={jobSelectedState}
              disabled={loadingJobs}
              type={"select"}
            >
              {selectAllText}
              <CheckboxContainer>
                <CheckboxButton
                  icon={<UncheckedBox size={"small"} />}
                  onClick={handleSelectButton}
                  checked={jobSelectedState}
                  checkedIcon={
                    <CheckedBox size={"small"}>
                      <CheckboxIcon size={"small"} />
                    </CheckedBox>
                  }
                />
                <CheckboxHover className="hover-check">
                  <CheckboxIcon size={"small"} />
                </CheckboxHover>
              </CheckboxContainer>
            </SquareButton>
          </HeaderButtonContainer>
        </HeaderContainer>
        <div>
          <JobListContainer ref={jobListRef}>
            {isVisitor
              ? Array.from({ length: 5 }).map((_, index) => {
                  return <VisitorJobCard key={index} />;
                })
              : loadingJobs
              ? Array.from({ length: 5 }).map((_, index) => {
                  return <JobCardSkeleton key={index} />;
                })
              : filteredJobs.map((job, index) => (
                  <ForYouJobCard
                    key={job?.id}
                    index={index}
                    job={job}
                    setInView={handleSetInView}
                    selectedJob={selectedJob}
                    handleSelectJob={handleSelectJob}
                    handleHideJob={handleHideJob}
                    jobDiscovery={jobDiscovery}
                  />
                ))}
          </JobListContainer>

          {!isDesktop && (
            <ScrollIndicatorContainer>
              {Array.from({ length: filteredJobs?.length }).map((_, index) => {
                return (
                  <ScrollIndicator key={index} selected={inView === index} />
                );
              })}
            </ScrollIndicatorContainer>
          )}
        </div>

        {!isVisitor && (
          <FooterContainer>
            <RoundedButton
              variant={loadingJobs ? "filled" : "outlined"}
              disabled={loadingJobs}
              onClick={handleRefreshJobs}
            >
              {RefreshButtonText}
              <RefreshIcon />
            </RoundedButton>
            {!loadingJobs && (
              <RoundedButton
                variant="filled"
                color="primary"
                disabled={disableSelectedButton}
                onClick={handleApplyToSelected}
              >
                {selectedText}
              </RoundedButton>
            )}
          </FooterContainer>
        )}
      </JobListWrapper>
      <ForYouResumeDialog />
      <RefreshDialog
        handleRefreshJobs={handleRefreshJobs}
        jobSelectedState={jobSelectedState}
      />
    </>
  );
}
